import { COMMENT_TYPE, countComments, PRODUCT_TAB_TYPE } from '@/helpers';
import ProductTabDesc from '@/modules/Product/components/ProductTabs/ProductTabDesc/index.vue';
import ProductTabAdditional from '@/modules/Product/components/ProductTabs/ProductTabAdditional/index.vue';
import CommentsBlock from '@/components/custom/CommentsBlock/index.vue';
import { MEDIA } from '@/helpers/variables';
import { mapGetters } from 'vuex';
import ProductAccordion from '@/modules/Product/components/ProductAccordion/index.vue';

export default {
  name: 'ProductTabs',
  components: { ProductAccordion, CommentsBlock, ProductTabAdditional, ProductTabDesc },
  props: {
    data: {
      type: [Object, null],
      default: null
    }
  },

  data() {
    return {
      MEDIA,
      PRODUCT_TAB_TYPE,
      COMMENT_TYPE,
      tabs: [
        {
          id: PRODUCT_TAB_TYPE.DESCRIPTION,
          title: this.$t('page.product.tabs.titleDesc')
        },
        {
          id: PRODUCT_TAB_TYPE.ADDITIONAL,
          title: this.$t('page.product.tabs.titleAdditional')
        },
        {
          id: PRODUCT_TAB_TYPE.COMMENTS,
          title: this.$t('page.product.tabs.titleComments')
        }
      ],
      currentTab: PRODUCT_TAB_TYPE.DESCRIPTION,
      commentsCount: 0
    };
  },
  created() {},
  mounted() {},
  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          console.log('newWal', this.data);
          if (this.data.feedbacks && this.data.feedbacks.data.length) {
            this.commentsCount = countComments(this.data.feedbacks.data, COMMENT_TYPE.FEEDBACKS);
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      appSize: 'app/size'
    })
  },
  methods: {
    onUpdateCounter() {
      this.commentsCount++;
    }
  }
};
