import StarRating from 'vue-star-rating';
import { getImage, routeToProduct } from '@/helpers';

export default {
  name: 'ProductPopularCard',
  methods: { routeToProduct },
  components: {
    StarRating
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    image() {
      return getImage(this.product.image);
    }
  }
};
