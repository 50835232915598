import { Carousel, Slide } from 'vue-carousel';
import ProductCard from '@/components/cards/ProductCard/index.vue';
import IconArrowPrevLight from '@/components/icons/IconArrowPrevLight.vue';
import IconArrowNextLight from '@/components/icons/IconArrowNextLight.vue';

export default {
  name: 'ProductSeries',
  components: { IconArrowNextLight, IconArrowPrevLight, ProductCard, Carousel, Slide },
  props: {
    data: {}
  },

  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    onCarouselNav($event, direction) {
      const carousel = this.$refs['product-carousel'];
      carousel.advancePage(direction);
    }
  }
};
