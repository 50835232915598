import Vue from 'vue';
import IconArrowPrevDark from '@/components/icons/IconArrowPrevDark.vue';
import IconArrowNextDark from '@/components/icons/IconArrowNextDark.vue';
import { mapGetters } from 'vuex';
import { MEDIA } from '@/helpers/variables';
import IconPlayFill from '@/components/icons/IconPlayFill.vue';
import { PRODUCT_SLIDER_ITEM_TYPE } from '@/helpers';

export default {
  components: { IconPlayFill, IconArrowNextDark, IconArrowPrevDark },
  props: {
    data: {}
  },
  name: 'ProductSlider',
  data() {
    return {
      MEDIA,
      PRODUCT_SLIDER_ITEM_TYPE,
      activeIndex: 0,
      widthItemView: 0,
      heightItemView: 0,
      index: null,
      videoEnded: true
    };
  },
  mounted() {
    Vue.nextTick(() => {
      this.widthItemView = this.$refs.viewContainer.offsetWidth;
      this.heightItemView = this.$refs.viewContainer.offsetHeight;
    });
  },
  watch: {
    'appSize.w': {
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this.widthItemView = this.$refs.viewContainer.offsetWidth;
          this.heightItemView = this.$refs.viewContainer.offsetHeight;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      appSize: 'app/size'
    })
  },
  methods: {
    changeActiveSlideIndex(index) {
      this.activeIndex = index;
    },
    onChangeSlide(direction) {
      try {
        this.$refs.video[0].pause();
        this.videoEnded = true;
      } catch (e) {}

      if (direction === 'prev') {
        if (this.activeIndex > 0) {
          this.activeIndex--;
        }
      } else if (direction === 'next') {
        if (this.activeIndex < this.data.length - 1) {
          this.activeIndex++;
        }
      }
    },
    onVideoEnded() {
      this.videoEnded = true;
      this.$refs.video[0].pause();
    },
    onVideoPlay() {
      this.videoEnded = false;
    },
    onPlayVideo() {
      this.$refs.video[0].play();
    },
    onPauseVideo() {
      this.$refs.video[0].pause();
      this.videoEnded = true;
    }
  }
};
