import _ from 'lodash';
import { getProductPriceTitle } from '@/helpers';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import IconMinus from '@/components/icons/IconMinus.vue';
import IconPlus from '@/components/icons/IconPlus.vue';

export default {
  name: 'ProductInfo',
  components: { IconPlus, IconMinus },
  props: {
    productData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['change-selected-price'],
  data() {
    return {
      selectedCount: 1,
      leftoverType: null,
      selectedProductPrice: null
    };
  },
  created() {
    this._checkValue();
  },
  computed: {
    ...mapGetters({
      isUserWithCode: 'profile/isUserWithCode',
      basket: 'basket/basket',
      showNotifyLoader: 'products/showNotifyLoader',
      changeFastBuyPopup: `popups/CHANGE_FAST_BUY_POPUP`
    }),
    isCanBePurchased() {
      return !(this.leftoverType.displayType === 'out-stock') && !this.productData.hasDuplicate;
    },
    /*unauthorised or authorised without code*/
    isRegularUser() {
      return !this.isUserWithCode;
    }
  },
  methods: {
    ...mapActions({
      fetchDefaultPrices: 'basket/GET_DEFAULT_PRICE_BASKET',
      showNotifyPopup: 'products/SHOW_POPUP_PRODUCT_WHEN_AVAILABLE'
    }),
    ...mapMutations({
      addToBasket: `basket/ADD_ITEM_TO_BASKET`,
      changeBasketPopup: 'basket/SHOW_BASKET_POPUP'
    }),
    getProductPriceTitle,
    _checkValue() {
      const productPrices = this.productData.productPrices.data;

      const productPrice = _.find(productPrices, (item) => this.isSelectedPrice(item));

      this.onSelectProductPrice(productPrice || productPrices[0]);
    },
    _setSelectedCount(count, productPrice = null) {
      this.selectedCount = 0;
      const setQuantity = Math.min(
        Math.max(Number.parseFloat(count || '1'), 1),
        (productPrice || this.selectedProductPrice).quantity
      );

      return (this.selectedCount = Math.ceil(setQuantity));
    },
    /**
     * @param {ProductPrice} productPrice
     * @return {string}
     */
    getBasePriceForUser(productPrice) {
      if (this.isUserWithCode) {
        const clientProductPrice = _.find(this.clientPrices, (price) => price.value === productPrice.value);

        if (!clientProductPrice) {
          return '0';
        }

        return clientProductPrice.basePrice.toFixed(0);
      }
      return '0';
    },

    isSelectedPrice(productPrice) {
      return parseInt(this.$route.query.value) === parseInt(productPrice.value);
    },
    onSelectProductPrice(productPrice) {
      if (!this.leftoverType) {
        this.leftoverType = productPrice.leftoverType.data;
      }

      this.selectedProductPrice = productPrice;

      if (!this.isSelectedPrice(productPrice)) {
        this.leftoverType = productPrice.leftoverType.data;
        this._setSelectedCount(this.selectedCount, productPrice);
        let { name, query, params } = this.$route;
        this.$router.push({ name, params, query: { ...query, value: productPrice.value } }).catch(() => {});
      }

      this.$emit('change-selected-price', this.selectedProductPrice);
    },
    onChangeCount(type, count) {
      console.log('onChangeCount', { type, count });
      if (type === 'decrement' && this.selectedCount > 1) {
        return this._setSelectedCount(this.selectedCount - 1);
      } else if (type === 'increment') {
        return this._setSelectedCount(this.selectedCount + 1);
      } else {
        return this._setSelectedCount(count);
      }
    },
    onAddBasket() {
      this.addToBasket({
        select_count: this.selectedCount,
        product_price: this.selectedProductPrice,
        product: this.productData,
        unauthorizedProductPriceId: this.productData.unauthorizedProductPriceId
      });
      fbq('track', 'AddToCart', {
        content_type: 'product',
        currency: 'UAH',
        value: this.selectedProductPrice.price,
        content_name: this.productData.title,
        content_ids: this.fetchDefaultPrices.unauthorizedProductPriceId,
        content_category: this.productData.brandSeries?.title || this.productData.brand?.title || this.productData.title
      });
      this.changeBasketPopup(true);
      this.$toasted.success(this.$t('toast.successAddBasket'));
    },

    onShowNotify() {
      const payload = {
        product_id: this.productData.id,
        product_price_id: this.selectedProductPrice.id
      };

      this.showNotifyPopup(payload);
    },

    onOpenFastBuy() {
      this.changeFastBuyPopup({
        show: true,
        product: {
          select_count: 1,
          select_type: this.selectedProductPrice,
          ...this.productData
        }
      });
    }
  }
};
