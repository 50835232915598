import { VueSlideToggle } from 'vue-slide-toggle';
import IconArrowDown from '@/components/icons/IconArrowDown.vue';

export default {
  name: 'ProductAccordion',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    description: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    IconArrowDown,
    VueSlideToggle
  },
  data() {
    return {
      open: false
    };
  }
};
