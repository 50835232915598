export default {
  name: 'ProductTabDesc',
  props: {
    description: {
      type: [String, null],
      default: null
    },
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {}
};
