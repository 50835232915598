export default {
  name: 'ProductTabAdditional',
  props: {
    categories: {
      type: [Array, null],
      default: () => null
    }
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {}
};
